import React, {useEffect, useState} from 'react';
import Banner from "./banner";
import Location from "./location";
import Layout from "../layout/layout";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import {checkData} from "../../helper";
import Seo from "../layout/seo";
import Loader from "../components/Loader";
import Breadcrumbs from "../components/breadcrumbs";

function Index(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);


    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true);
        const response = await axios.get(`${baseURL}/contact-page`);
        setUserData(response.data.data);
        setIsLoaded(false);
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);

    return (
        <Layout>
            {isLoaded ?
                <Loader/>

                :
                <>
                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Breadcrumbs link1="TekRevol" link2="Contact"/>
                    <Banner data={userData}/>
                    <Location data={userData.locations}/>
                </>
            }
        </Layout>
    );
}

export default Index;